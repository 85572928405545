* {
	box-sizing: border-box;
	font-family: 'Inter', sans-serif;
	-webkit-text-size-adjust: 100%;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-text-size-adjust: none;
	-moz-osx-font-smoothing: antialiased;
	-moz-text-size-adjust: none;
	-moz-font-smoothing: antialiased;
	text-size-adjust: none;
	font-smoothing: antialiased;
}

html,
body {
	margin: 0;
	display: flex;
	font-size: 16px;
	width: 100%;
	min-height: 100vh;
	color: #676a6c;

	@media screen and (min-width: 601px) {
		background-color: #FFFFFF;
		margin: 0 auto;
	}
}

#root {
	width: 100%;
	height: 100%;
	flex: 1;
	display: flex;
}

.bg--dark {
	background-color: #111111;
}

.bg--white {
	background-color: #111111;
}

.af-button {
	font-size: 14px;
	padding: 16px 8px;
	border-radius: 8px;

	@media only screen and (max-width: 600px) {
		border-radius: 8px;
	}
}

.af-button--icon {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 768px) {
		width: 56px;
		height: 56px;

		.icon {
			width: 24px;
			height: 24px;
		}
	}
}

.af-button--error {
	border: 1px solid #FF002A;
	background-color: #FFE5EA;
}

.af-button--error:hover {
	border: 1px solid #FF002A;
	background-color: #FFCCD6;
}

a {
	color: #676a6c;
    text-decoration: none;

	&:visited,
	&:active {
		color: #676a6c;
	}
}

a.underline {
	text-decoration: underline;
}

p {
	margin-top: 0;
	margin-bottom: 0;
}

button {
	cursor: pointer;
}

/* Utils */
.w-100 {
	width: 100%;
}

.text--left {
	text-align: left;
}

.text--center {
	text-align: center;
}

.text--bold {
	font-weight: bold;
}

.text--light {
	color: #7b8e9d;
}

.text--primary {
	color: #0069BF;
}

.text--scroll {
	overflow: auto;
}

.text--pre-wrap {
	white-space: pre-wrap;
}

.text--12 {
	font-size: 12px;
}

.text--14 {
	font-size: 14px;
}

.text--28 {
	font-size: 28px;
}

.m-t-4 {
	margin-top: 4px !important;
}

.m-t-8 {
	margin-top: 8px !important;
}

.m-t-16 {
	margin-top: 16px !important;
}

.m-t-20 {
	margin-top: 20px !important;
}

.m-t-24 {
	margin-top: 24px !important;
}

.m-t-28 {
	margin-top: 28px !important;
}

.m-r-4 {
	margin-right: 4px !important;
}

.m-t-32 {
	margin-top: 32px !important;
}

.m-t-60 {
	margin-top: 60px !important;
}

.m-t-auto {
	margin-top: auto !important;
}

.m-b-10 {
	margin-bottom: 10px !important;
}

.m-b-16 {
	margin-bottom: 16px !important;
}

.m-b-24 {
	margin-bottom: 24px !important;
}

.m-b-40 {
	margin-bottom: 40px !important;
}

.m-b-60 {
	margin-bottom: 60px !important;
}

.m-r-8 {
	margin-right: 8px !important;
}

.m-r-16 {
	margin-right: 16px !important;
}

.m-r-24 {
	margin-right: 24px !important;
}

.m-l-8 {
	margin-left: 8px !important;
}

.m-l-16 {
	margin-left: 16px !important;
}

.p-y-60 {
	padding-top: 60px;
	padding-bottom: 60px;
}

.p-x-60 {
	padding-left: 60px;
	padding-right: 60px;
}

.p-x-8 {
	padding-left: 8px;
	padding-right: 8px;
}

.p-y-8 {
	padding-top: 8px;
	padding-bottom: 8px;
}

.p-x-16 {
	padding-left: 16px;
	padding-right: 16px;
}

.p-x-24 {
	padding-left: 24px;
	padding-right: 24px;
}

.p-y-20 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.ds-flex {
	display: flex;
}

.ds-flex.ds-flex__wrap {
	flex-wrap: wrap;
}

.ds-flex.ds-flex__column {
	flex-direction: column;
}

.ds-flex.ds-flex__row {
	flex-direction: row;
}

.ds-flex.ds-flex__align-self--start {
	align-self: flex-start;
}

.ds-flex.ds-flex__align-self--end {
	align-self: flex-end;
}

.ds-flex.ds-flex__align--center {
	align-items: center;
}

.ds-flex.ds-flex__align--end {
	align-items: flex-end;
}

.ds-flex.ds-flex__align--start {
	align-items: flex-start;
}

.ds-flex.ds-flex__justify--end {
	justify-content: flex-end;
}

.ds-flex.ds-flex__justify--center {
	justify-content: center;
}

.ds-flex.ds-flex__justify--space-between {
	justify-content: space-between;
}

.ds-flex.ds-flex__item {
	flex: 1;
}

.ds-flex.ds-flex__grow--1 {
	flex-grow: 1;
}

.ds-flex.ds-flex__grow--2 {
	flex-grow: 2;
}

.Toastify__toast-container {
	width: calc(100% - 32px);
    margin: 16px !important;
    left: 0 !important;
    transform: translate(0);
    right: 0 !important;
}

@media only screen and (min-width: 600px) {
	.Toastify__toast-container {
        width: 50% !important;
        transform: translateX(50%) !important;
		margin: 0 !important;
	}
}

.doca-alert__text {
	white-space: pre-line !important;
}

.onoffswitch {
	position: relative;
	width: 50px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.onoffswitch-checkbox {
	display: none;
}

.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	height: 29px;
	padding: 0;
	line-height: 25px;
	border: 2px solid #ccc;
	border-radius: 25px;
	background-color: #d9d9d9;
	transition: background-color 0.1s ease-in;
}

.onoffswitch-label:before {
	content: "";
	display: block;
	width: 25px;
	height: 25px;
	margin: 0px;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 23px;
	border: 2px solid #ccc;
	border-radius: 25px;
	transition: all 0.1s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
	background-color: #008CFF;
}

.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
	border-color: #008CFF;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
	right: 0px;
}

.af-button--primary:disabled,
.af-button--secondary:disabled {
	color: #fff;
	border: 1px solid #E7EAEC;
	background-color: #E7EAEC;
	box-shadow: none;
}

.af-button--primary {
	border: 0;
	color: #fff;
	border: 1px solid #008cff;
	background-color: #008cff;
}

.af-button--secondary {
	color: #676A6C;
	background-color: white;
	border: 1px solid #D7DFE2;
}

.af-button--secondary--hovered:hover {
    background-color: #E5F4FF;
}

.af-button--danger {
	border: 0;
	color: #fff;
	background-color: #BF0020;
}

.btn-group.open .af-button {
	box-shadow: none;
}

.af-button--terciary {
	color: #004680;
	border: 1px solid #004680;
	background-color: white;
}

.af-button--primary:hover {
	background-color: #2C70C5;
	color: #fff;
}

.af-button--secondary:hover {
	box-shadow: 0 0px 0px 1px #008cff;
}

.af-button--terciary:hover {
	color: #0069BF;
	border: 1px solid #0069BF;
}

.af-button--primary:active {
	background-color: #1F5494;
}

.af-button--secondary:active {
	border: 1px solid #A7B1C2;
	background-color: #E7EAEC;
}

.af-input {
	width: 100%;
	height: 40px;
	padding: 8px;
	border-radius: 8px;
}

input:focus, input:active {
	outline: 0;
	border-color: #3498db!important;
	box-shadow: none;
}

.doca-shortcut--active {
	background-color: #E5F4FF !important;
    border: 2px solid #008cff !important;
}

// .doca-form__field {
// 	flex: 1;
// }

.d-block {
	display: block;
}

.doca-shortcut--small {
    padding: 0.65rem 0.25rem !important;
}

.doca__current-day::before {
	content: ".";
    position: absolute;
	transform: translateX(-2px);
    font-size: 22px;
    bottom: 5px
}

.doca__current-day {
	position: relative;
}

.doca-slots__container {
    display: grid !important;
	gap: 1em;
	grid-auto-columns: 1fr;
	grid-template-columns: repeat(3, 1fr);
}

.doca-steps__container {
	width: auto !important;
}

.doca-shortcut__container {
    display: grid !important;
	gap: 1em;
	grid-auto-columns: 1fr;
	grid-template-columns: 47% 47%;
}

.place-list__container {
	background: white;
    border: 1px solid #D9E1E8;
    border-radius: 8px;
    padding: 16px;
    margin-top: 7px;
}

.place-list__item:hover {
	background: red;
}

.checkbox--absolute-input {
	position: absolute;
    right: 13px;
    top: 10px;
}

.hidden {
	display: none !important;
}

.carousel__container--list .carousel__container--list__item:not(:last-child) {
	margin-right: 12px;
}

.carousel-list__image--active {
	border: 2px solid #676a6c !important;
	padding: 2px;
}

.text--primary {
	color: #008cff !important;
}

.text--danger {
	color: #ff0033 !important;
}

.carousel__container .carousel-root {
	height: 100%;
    display: flex;
    flex-direction: column;
}

.carousel__container .carousel-root {
	height: 100%;
    display: flex;
    flex-direction: column;
}

.carousel__container .carousel:not(.carousel-slider) {
	flex: 0.2;
}

.carousel__container .carousel.carousel-slider {
	flex: 0.8;
}

.carousel__container .slider-wrapper.axis-horizontal, .carousel__container .slider.animated {
	height: 100%;
}

.carousel .thumbs-wrapper {
    margin: 20px 0 !important;
    overflow: hidden;
}

.flex-column {
    flex-direction: column;
}

.b-radius-0 {
	border-radius: 0 !important;
}

.patient-card {
	position: relative;
}

.patient-card:hover {
	cursor: pointer !important;
	--tw-bg-opacity: 1 !important;
	background-color: rgb(242 245 247/var(--tw-bg-opacity)) !important;
	border-width: 1px !important;
}

.patient-card:active {
	cursor: pointer !important;
	--tw-bg-opacity: 1 !important;
	background-color: rgb(235 245 255 / var(--tw-bg-opacity)) !important;
	border-color: #008cff !important;
	border-width: 1px !important;
}
